import gql from 'graphql-tag'

export const QUERY_GET_CONNECTIONS_PAGINATED = gql`
  query fridayConnections($filters: FridayConnectionFilter!, $pagination: PaginationParams!) {
    fridayConnections(filters: $filters, pagination: $pagination) {
      data {
        _id
        account_id
        active
        machine_id
        instance_id
        created_at
        updated_at
      }
    }
  }
`

export const QUERY_GET_STORAGE_CONFIG = gql`
  query getStorageConfig($accountId: String!) {
    getStorageConfig(accountId: $accountId) {
      gcs {
        auth_provider_x509_cert_url
        auth_uri
        client_email
        client_id
        client_x509_cert_url
        private_key_id
        private_key
        project_id
        token_uri
        type
        universe_domain
      }
    }
  }
`

export const MUTATION_CREATE_STORAGE = gql`
  mutation insertStorageConfig($accountId: String!, $storageConfig: StorageConfigInsertInput!) {
    insertStorageConfig(accountId: $accountId, storageConfig: $storageConfig) {
      message
      success
    }
  }
`

export const MUTATION_UPDATE_STORAGE = gql`
  mutation updateStorageConfig($accountId: String!, $storageConfig: StorageConfigInsertInput!) {
    updateStorageConfig(accountId: $accountId, storageConfig: $storageConfig) {
      message
      success
    }
  }
`

export const MUTATION_CREATE_CONNECTION = gql`
  mutation registerFridayConnection($account_id: ObjectId!, $machine_id: String!, $instance_id: String!) {
    registerFridayConnection(connection: { account_id: $account_id, machine_id: $machine_id, instance_id: $instance_id }) {
      message
      success
    }
  }
`

export const MUTATION_CREATE_INTEGRATION = gql`
  mutation registerFridayIntegrator($data: FridayIntegratorInsertInput!) {
    registerFridayIntegrator(data: $data) {
      message
      success
    }
  }
`

export const MUTATION_CHANGE_STATUS_CONNECTION = gql`
  mutation changeStatusFridayConnection($connection_id: String!, $disable: Boolean!) {
    changeStatusFridayConnection(connection_id: $connection_id, disable: $disable) {
      message
      success
    }
  }
`

export const QUERY_GET_CONNECTION_AND_INTEGRATORS = gql`
  query fridayConnectionWithIntegrator($connection_id: String!) {
    fridayConnectionWithIntegrator(connection_id: $connection_id) {
      _id
      account_id
      active
      machine_id
      instance_id
      integrators {
        _id
        active
        config {
          host
          port
          user
          password
          database
          driver
          dest_dir
          source
          provider
          query
          bucket
          prefix
          mercashop {
            secret_key
            units
            url
          }
          erp {
            cgo
            channel_sell_seq
            company_id
            diretory
            fees_code
            id_store
            key
            number_segment_sales
            number_table_sales
            password
            seller
            sku_freight
            store_id
            token
            url
            user
            version
            base_id_order
            directory
            type_freight_code
            authorization_code
          }
        }
        cron_schedule
        executor
        job_id
        machine_id
        type
      }
    }
  }
`

export const MUTATION_UPDATE_CONNECTION = gql`
  mutation updateFridayConnection($connection: FridayConnectionUpdate!, $connection_id: String!) {
    updateFridayConnection(connection: $connection, connection_id: $connection_id) {
      message
      success
    }
  }
`
export const MUTATION_UPDATE_INTEGRATOR = gql`
  mutation updateFridayIntegrator($update: FridayIntegratorUpdateInput!) {
    updateFridayIntegrator(update: $update) {
      message
      success
    }
  }
`
