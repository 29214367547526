export const SUB_ORIGINS_ENUM_MAPPER = {
  // Current - Parcerias
  Ambev: 'AMBEV', // *
  'Evento Indicação': 'EVENTO_INDICACAO',
  'Evento Prospecção': 'EVENTO_PROSPECCAO',
  Inbound: 'INBOUND', // *
  Outbound: 'OUTBOUND', // *
  Mercapromo: 'MERCAPROMO',
  'Pós-evento Lista': 'POS_EVENTO_LISTA',
  'Indicação Ativa': 'INDIC_ATIVA',
  'Pré-evento': 'PRE_EVENTO',
  // Current - Outbound
  'Indicação CS': 'INDICACAO_CS',
  'Indicação Interna': 'INDICACAO_INTERNA', // *
  Parcerias: 'PARCERIAS', // *
  'Recuperação Pré Vendas': 'RECUP_PRE_VENDAS',
  'Recuperação Inbound': 'RECUP_INBOUND', // *
  'Recuperação Ambev': 'RECUP_AMBEV',
  'Prospecção Ativa Vendas': 'PROSPECCAO_VENDAS',
  // Current - Inbound
  'Campanha Mídia CBM': 'CAMPANHA_MIDIA_CBM',
  // Current - Ambev
  'Indicação Direta': 'INDIC_DIRETA',
  'Lista Base Clientes': 'LISTA_BASE_CLIENTES',

  // Deprecated
  Eventos: 'EVENTOS',
  Landpage: 'LANDPAGE',
  Piloto: 'PILOTO',
  'The Hub': 'THE_HUB',
  'Recuperação Smart': 'RECUP_SMART',
  'CR Cartões': 'CR_CARTOES',
  Trial: 'TRIAL',
  'Top 500': 'TOP_500',
  'Top 50': 'TOP_50',
  'Leads Estratégicos': 'LEADS_ESTRAT',
  'Cross-Sell Ecommerce - Tricard': 'CROSS_SELL_ECOMM_TRICARD',
  'Recuperação Tricard': 'RECUP_TRICARD',
  Arius: 'ARIUS',
  Avance: 'AVANCE',
  Avanco: 'AVANCO',
  Ciss: 'CISS',
  'Eventos Parcerias': 'EVENTOS_PARCERIAS',
  GDI: 'GDI',
  EGestore: 'EGESTORA',
  Logus: 'LOGUS',
  Oriontec: 'ORIONTEC',
  'Processo de Nutrição': 'PROCESSO_NUTRI',
  'Programa Indica': 'PROG_INDICA',
  'RP Info': 'RP_INFO',
  SD: 'SD',
  'Mídia Paga': 'MIDIA_PAGA',
  'Redes Sociais': 'REDES_SOCIAIS',
  'Tráfego Pago': 'TRAFEGO_DIRETO',
  Email: 'EMAIL',
  SMS: 'SMS',
  'Busca Orgânica': 'BUSCA_ORGANICA',
  Materiais: 'MATERIAIS',
  '9 Bits': 'NINE_BITS',
  Live: 'LIVE',
  'Evento Presencial': 'EVENTO_PRESENCIAL',
  'Cross-Sell Ecommerce - Pré Vendas': 'CROSS_SELL_ECOMM_PRE_VENDAS',
  Google: 'GOOGLE',
  'Social Selling': 'SOCIAL_SELLING',
  'Prospecção CS': 'PROSPECCAO_CS',
  'Eventos Marketing': 'EVENTOS_MARKETING'
}
